
const configFileName = process.env.REACT_APP_CONFIG_FILE;
const config = require(`../${configFileName}`);

export const limitPerPage = 100;

export const apiGetMessages = async (userId, page = 1) => {

    if (!userId || userId === undefined) {
        return []
    } else {
        let messages = {}


        await fetch(`${config.apiUrl}/ema/messages?userId=${userId}&page=${page}&per_page=${limitPerPage}&tenant=${config.tenant}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.apiKey}`
            }
        })
            .then((response) => {
                messages = response.json();
            })
            .catch((err) => {
                console.log(err.message);
                return [];
            })
        return messages

    }
};


export const apiSendMessage = async (userId, message) => {
    let reply = {};
    await fetch(`${config.apiUrl}/ema/talk`, {
        method: 'POST',
        body: JSON.stringify({
            message: message,
            userId: userId,
            tenant: config.tenant
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${config.apiKey}`
        }
    })
        .then((response) => response.json())
        .then((data) => {
            reply = data
        })
        .catch((err) => {
            console.log(err.message)
        })
    return reply;

};


export const apiCreateUser = async () => {
    console.log("Creating user");
    console.log("Tenant id is ", config.tenant);
    const response = await fetch(`${config.apiUrl}/ema/user`, {
        method: 'POST',
        body: JSON.stringify({
            tenant: config.tenant
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${config.apiKey}`
        },
    });
    return response.json();
}