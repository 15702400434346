import React, { useEffect, useRef, useState } from "react";
import './Spinner.css'

const Spinner = (props) => {

    return (
        <span className="loader"></span>
    )
};

export default Spinner;