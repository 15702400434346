import React, { useState } from "react";
import './Suggestion.css'

const Suggestion = (props) => {
    const { text, sendMessage, setHome } = props;
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        if (!clicked) {
            sendMessage({ type: 'user', message: text });
            setClicked(true);
            setHome(false);
        }
    };

    return (
        <div className={`Suggestion ${clicked ? 'clicked' : ''}`} onClick={handleClick}>
            {text}
        </div>
    );
};

export default Suggestion;
