import React, { useEffect, useRef, useState } from "react";
import './Message.css'
import userIcon from '../../Assets/Images/userIcon.svg'


const configFileName = process.env.REACT_APP_CONFIG_FILE;
const config = require(`../../${configFileName}`);


function formatText(text) {
    if (typeof text !== 'string') {
      return text;
    }
  
    // Remove text enclosed in 【】
    let formattedText = text.replace(/【.*?】/g, '');
  
    // Replace markdown bold and italic syntax with HTML tags
    formattedText = formattedText
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
      .replace(/\*(.*?)\*/g, '<em>$1</em>');           // Italic
  
    // Replace newline characters with <br> tags
    formattedText = formattedText.replace(/\n/g, '<br />');

    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    formattedText = formattedText.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    return formattedText;
  }
  
const Message = (props) => {

    const [logoSrc, setLogoSrc] = useState('');

    useEffect(() => {
      let logo = config.logo;
      if (!logo) {
        logo = 'ema-orb.svg';
      }
  
      const images = require.context('../../Assets/Images', true);
      
      try {
        const logoImage = images(`./${logo}`);
        setLogoSrc(logoImage);
      } catch (error) {
        console.error("Logo image could not be loaded:", error);
      }
    }, []); // Empty dependency array means this effect runs once after initial render
  

    return (
        <div className="Chat-Container">
            {props.type === 'ema' ?
                <div style={{ justifyContent: "flex-start" }}>
                    <div className="Message-Wrapper-Ema">
                        <img className="iconOrbLeft" src={logoSrc} alt="logoScr" />
                        
                        <div className="TextArea"   dangerouslySetInnerHTML={{ __html: formatText(props.message) }}></div>
                    </div>
                </div>
                :
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <div className="Message-Wrapper-User">
                        <div className="TextArea" dangerouslySetInnerHTML={{ __html: formatText(props.message) }}></div>
                    </div> 
                    <img className="userIcon" src={userIcon} alt="userIcon" />
                </div>
            }

        </div>

    )
};

export default Message;