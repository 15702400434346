import React, { useEffect, useRef, useState } from "react";
import './ChatScroll.css'
import Message from "../Message/Message";
import "intersection-observer";
import TextInput from "../TextInput/TextInput";
import { apiGetMessages, apiSendMessage, apiCreateUser } from '../../Services/Api'
import { useCookies } from "react-cookie";
import Suggestion from "../Suggestion/Suggestion";
import Spinner from "../Spinner/Spinner";
import suggestions from '../../Data/suggestedPrompts.json';
import orb from '../../Assets/Images/orb.png'


const configFileName = process.env.REACT_APP_CONFIG_FILE;
const config = require(`../../${configFileName}`);

const limitPerPage = config.messageLimit;

const ChatScroll = () => {
    const [messages, setMessages] = useState([])
    const [lastMessage, setLastMessage] = useState('')
    const messagesEndRef = useRef(null)
    const [home, setHome] = useState(true)
    const intervalRef = useRef(null);
    const [intervalCounter, setIntervalCounter] = useState(0)
    const [limitReached, setLimitReached] = useState(false);
    const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [user, setUser] = useState(null);

    const loadMoreMessages = async () => {
        console.log("Loading older messages");
        if (!allMessagesLoaded) {
            // Check if the user has scrolled to the top of the chat container
            if (messagesEndRef.current.getBoundingClientRect().top >= 0) {
                // Load more messages from the previous page
                const nextPage = currentPage + 1;
                try {
                    console.log("Calling page " + nextPage);
                    const response = await apiGetMessages(user, nextPage);
                    if (response["messages"].length > 0) {
                        const olderMessages = response["messages"].map((message) => ({
                            type: message.sender === 'user' ? 'user' : 'ema',
                            message: message.message,
                        }));
                        setMessages((prevMessages) => [...olderMessages.reverse(), ...prevMessages]);
                        setCurrentPage(nextPage);
                    } else {
                        // All messages have been loaded
                        setAllMessagesLoaded(true);
                    }
                } catch (error) {
                    console.error("Error loading more messages:", error);
                }
            }
        } else {
            console.log("All messages have been loaded");
        }
    };




    useEffect(() => {
        if (user) {
            loadMessages();
            setHome(false)
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            console.log("intervalCounter: ", intervalCounter);
            console.log("user", user)
            if (intervalCounter > 0) {
                // Check your conditions and load messages if needed
                console.log("messages", messages)
                if (user && (messages.length === 0 || (messages.length > 0 && messages[messages.length - 1].type !== 'ema'))) {
                    loadMessages();
                }
                setIntervalCounter(intervalCounter - 1)
            }
        }, 3000)
    }, [messages, user, intervalCounter]); // Empty dependency array to run the effect only once when mounted



    const sendMessage = async (message) => {
        setHome(false);
        let response_user;
        message.timestamp = new Date().toISOString(); // Set timestamp to current time
        setMessages(messages => [...messages, message])
        if (!user) {
            response_user = await apiCreateUser();
            console.log("Created user ", response_user);
            console.log("Created user id ", response_user._id);
            //setCookies('user', response_user._id);
            //setCookies('user', response_user._id, { sameSite: 'None', secure: false });
            setUser(response_user._id);

        }
        console.log("Sending message");

        setLastMessage(message)
        apiSendMessage(user ? user : response_user._id, message.message)
        setIntervalCounter(30)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const loadMessages = async () => {
        console.log("Loading messages");
        console.log("user", user)
        if (user) {
            try {
                let response = await apiGetMessages(user);
                let buffer = response["messages"].map((message) => {
                    if (message.sender === 'user') {
                        return {
                            type: 'user',
                            message: message.message,
                            timestamp: message.timestamp
                        }
                    } else {
                        return {
                            type: 'ema',
                            message: message.message,
                            timestamp: message.timestamp

                        }
                    }
                })

                let sortedBuffer = buffer.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));


                if (buffer.length > 0) {
                    setMessages(sortedBuffer);
                }
                if (buffer.length > 0 && buffer[buffer.length - 1].type === 'ema') {
                    setIntervalCounter(0)
                } else if (intervalCounter < 2) {
                    setMessages([...messages, {
                        type: 'ema',
                        message: suggestions.find(item => item.tenant === config.tenant).busyMessage
                    }])
                    setIntervalCounter(0)
                    //scrollToBottom()
                }


                //If there is a limit, and its not in a quiz, we check it
                if (limitPerPage && ((!response["conversation"] || !(response["conversation"].status.toUpperCase() == "INQUIZ"))
                    && response["pagination"].total > limitPerPage)) {
                    clearInterval(intervalRef.current);
                    setMessages(messages => [...messages, {
                        type: 'ema',
                        message: suggestions.find(item => item.tenant === config.tenant).limitMessage
                    }]);
                    setLimitReached(true);
                }

            } catch (e) {
                console.log("Error loading messages", e);
            }
        }

    }

    const generateTranscript = () => {
        let bufferString = ''
        messages.forEach((message) => {
            if (message.type === 'ema') {
                bufferString = bufferString + 'Ema: '
            } else {
                bufferString = bufferString + 'You: '
            }
            bufferString = bufferString + message.message + '\n\n'
        })
        return bufferString
    }

    const downloadTranscript = () => {
        let transcript = generateTranscript()
        const element = document.createElement("a");
        const file = new Blob([transcript], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "myChat.txt";
        document.body.appendChild(element); // Required to work in FireFox
        element.click();
    }

    const regenerateResponse = () => {
        sendMessage(lastMessage)
    }



    useEffect(() => {
        //scrollToBottom()
    }, [messages]);


    return (
        <div className="Outer-Wrapper">
            {!home ?
                <div className="Chat-Scroll-Wrapper">
                    <div className="test-box">
                        {messages.map((message, index) => {
                            return <Message key={index} type={message.type} message={message.message}></Message>
                        })}
                        <div ref={messagesEndRef} />
                    </div>

                    {(isLoading()) && (
                        <Spinner></Spinner>
                    )}

                    {!limitReached && (
                        <div className="convInput">
                            <TextInput
                                placeholder="Ask a question..."
                                style={{ marginLeft: '20px' }}
                                disabled={isLoading()}
                                onPress={(message) => {
                                    sendMessage({ type: 'user', message: message });
                                }}
                            />
                        </div>
                    )}

                    <div className="poweredByEma">Powered by Ema</div>
                </div>
                :
                <div className="Home-content">
                   
                   <img src={orb} className="ema-orb" alt="orb" />

                    <div className="home-suggestions">
                        {suggestions.find(item => item.tenant === config.tenant).suggestions.map((suggestion, index) => (
                            <Suggestion
                                key={index}
                                text={suggestion.prompt}
                                sendMessage={sendMessage}
                                setHome={setHome}
                            />
                        ))}
                    </div>

                    {!limitReached && (
                        <div className="convInput">
                            <TextInput
                                placeholder="Ask a question..."
                                style={{ marginLeft: '20px' }}
                                disabled={false}
                                onPress={(message) => {
                                    console.log("limit reached", limitReached);
                                    if (!limitReached) {
                                        sendMessage({ type: 'user', message: message });
                                    }
                                }}
                            />
                        </div>
                    )}


                    <div className="poweredByEma">Powered by Ema</div>
                </div>

            }

        </div>
    )

    function isLoading() {
        return messages.length === 0 || messages[messages.length - 1].type !== 'ema';
    }
};



export default ChatScroll