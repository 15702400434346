import React, { useEffect, useRef, useState } from "react";
import './TextInput.css'

import sendIcon from '../../Assets/Images/paperplaneleft.svg'

const TextInput = (props) => {
    const textareaRef = useRef(null);
    const buttonWrapperRef = useRef(null);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        buttonWrapperRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
        buttonWrapperRef.current.style.height = scrollHeight + "px";
    }, [currentValue]);

    const sendMessage = () => {
        if (currentValue !== '') {
            props.onPress(currentValue)
            setCurrentValue('')
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevents new line on Enter
            sendMessage();
        }
    }

    return (
        <div className="Text-Input-Wrapper">
            <textarea
                ref={textareaRef}
                className="Input"
                value={currentValue}
                onChange={e => {
                    setCurrentValue(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                placeholder={props.placeholder}
                style={props.style}
                disabled={props.disabled}
            />
            <div className="Button-Wrapper2" ref={buttonWrapperRef}>
                <button
                    className="Button-wrapper"
                    onClick={sendMessage}
                    disabled={props.disabled}>
                    <img style={{ width: '20px', fill: "white" }} src={sendIcon} />
                </button>
            </div>
        </div>
    )
};

export default TextInput;
